var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subpage-layout submission" }, [
    _c(
      "div",
      { staticClass: "showcase-sidebar" },
      [
        _c("back-button", {
          attrs: {
            "showcase-id": _vm.showcaseId,
            "button-text": "Back to " + (_vm.showcase && _vm.showcase.name),
          },
        }),
        _vm._v(" "),
        _c("info"),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showcase
      ? _c(
          "div",
          { staticClass: "info-container" },
          [
            _c(
              "div",
              { staticClass: "show-mobile" },
              [
                _c("back-button", {
                  attrs: {
                    "showcase-id": _vm.showcaseId,
                    "button-text":
                      "Back to " + (_vm.showcase && _vm.showcase.name),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.submission && _vm.submission.title) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.isPublished ? _c("ShowcaseNavigationButtons") : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isPublished
              ? _c("div", { staticClass: "showcase-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "action-item like",
                      on: {
                        click: function ($event) {
                          return _vm.toggleLike(_vm.submissionId)
                        },
                      },
                    },
                    [
                      !_vm.isLiked(_vm.submissionId)
                        ? _c("icons", { attrs: { name: "showcase-favorite" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked(_vm.submissionId)
                        ? _c("icons", {
                            class: { fill: _vm.isLiked(_vm.submissionId) },
                            attrs: { name: "showcase-favorite-fill" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLiked(_vm.submissionId)
                        ? _c("span", [_vm._v("Like")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isLiked(_vm.submissionId)
                        ? _c("span", [_vm._v("Unlike")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "action-item follow",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFollow(_vm.submissionId)
                        },
                      },
                    },
                    [
                      _c("icons", {
                        class: { fill: _vm.isFollowed(_vm.submissionId) },
                        attrs: { name: "showcase-notifications" },
                      }),
                      _vm._v(" "),
                      !_vm.isFollowed(_vm.submissionId)
                        ? _c("span", [_vm._v("Follow")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFollowed(_vm.submissionId)
                        ? _c("span", [_vm._v("Unfollow")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "action-item share" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.mailToLink } },
                      [
                        _c("icons", { attrs: { name: "showcase-mail" } }),
                        _vm._v("\n          Share\n        "),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.sliderData
              ? _c(
                  "div",
                  [
                    _c("ShowcaseSlider", {
                      attrs: {
                        id: "showcase_submission_slider",
                        title: false,
                        slides: _vm.sliderData,
                        "slideshow-delay": "7000",
                        "show-controls": _vm.sliderData.length > 1,
                        "show-indicators": _vm.sliderData.length > 1,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("h4", [_vm._v("Objective:")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.submission.description,
                      expression: "submission.description",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h4", [_vm._v("Insights and Inspiration:")]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.insights,
                  expression: "insights",
                },
              ],
            }),
            _vm._v(" "),
            _vm.aon_impact
              ? [
                  _c("h4", [_vm._v("Impact:")]),
                  _vm._v(" "),
                  _c("p", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.aon_impact,
                        expression: "aon_impact",
                      },
                    ],
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("info", { attrs: { classes: "show-mobile info-wrapper" } }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h4", [
              _vm._v("Comments (" + _vm._s(_vm.comments.length) + ")"),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onCommentSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.comment,
                      expression: "comment",
                    },
                  ],
                  attrs: { placeholder: "Type your comment here…" },
                  domProps: { value: _vm.comment },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.comment = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "spacer-10" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn primary link-button",
                    attrs: {
                      type: "submit",
                      title: _vm.$t("site_actions_submit_label"),
                    },
                  },
                  [_vm._v("\n        Submit Comment\n      ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "comment-section" },
              _vm._l(_vm.comments, function (item, index) {
                return _c("div", { key: index, staticClass: "comment" }, [
                  _c("div", { staticClass: "meta" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(item.commenter) + " | " + _vm._s(item.date)
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._isAdminUser
                      ? _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editComment(item)
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(item.message))]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "submission-navigation" },
              [
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", {
                      on: { navClick: _vm.onNavClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }