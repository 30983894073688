/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false,
    showcase: {
      search_placeholder_text: "What are you looking for?"
    }
  },
  icons: {
    type: 'default',
    custom: {
      ic_arrow_drop_down: {
        viewbox: '0 0 24 24',
        content: '<path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z"/>'
      },
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<svg id="ic_tune" viewBox="0 0 24 24"><path d="M3 17 v2h6v-2 H3zM3 5 v2h10V5H3zm10 16 v-2 h8v-2 h-8 v-2 h-2 v6h2zM7 9 v2H3v2h4v2h2V9H7zm14 4 v-2 H11v2h10zm-6-4 h2V7h4V5h-4 V3h-2 v6z "></path></svg>'
      }
    }
  }
}
