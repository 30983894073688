var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-intro" },
    [
      _c("h2", [
        _vm._v(
          "\n    See the best examples of the Aon Story coming to life around the world.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("h2", [
        _vm._v(
          "\n    Have you and your team created something amazing? Share your work here.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "btn primary",
          attrs: {
            to: _vm.$generateRoute({
              name: "showcase.submit",
              params: {
                showcaseId: _vm.showcaseId,
              },
            }),
          },
        },
        [_vm._v("\n    Submit\n  ")]
      ),
      _vm._v(" "),
      _c("h1", [_vm._v("Featured Work")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }